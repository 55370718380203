<template>
  <div class="select-renderer">
    <v-select
      :class="[dynamicClass]"
      v-model="selectedOption"
      :options="options"
      :placeholder="placeholder"
      :clearable="true"
      :no-async="true"
      appendToBody
      v-on:input="onSelectChange"
      :getOptionLabel="
        (option) => {
          return option.name
        }
      "
    >
      <template #selected-option="{ name, status }">
        <span v-if="status !== 'active'" style="text-decoration: line-through">{{ name }}</span>
        <span v-else>{{ name }}</span>
      </template>
      <template #option="{ name, status }">
        <span v-if="status !== 'active'" style="text-decoration: line-through">{{ name }}</span>
        <span v-else>{{ name }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  data() {
    return {
      options: [],
      selectedOption: null,
      placeholder: ''
    }
  },
  computed: {
    dynamicClass() {
      if (!this.selectedOption) {
        return 'v-select-empty'
      }
      return ''
    }
  },
  mounted() {
    this.options = this.params.options
    this.selectedOption = this.options.find(
      (o) => o.name === this.params?.value?.name
    )
    this.placeholder = this.params.selectDefaultLabel
  },
  components: {
    vSelect
  },
  methods: {
    onSelectChange(selectedValue) {
      this.params.node.setDataValue(this.params.colDef.field, selectedValue)
    }
  }
}
</script>
