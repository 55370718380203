<template>
  <hero-navigation>
    <div class="projects-nav-elements">
      <router-link v-if="isAdmin" class="button-outline-blue" to="/settings/users-rates-activity-tags">
        Users, Rates, Activity Tags
        <sup v-if="isAdmin" class="showStatus">A</sup>
      </router-link>
      <router-link class="button-outline-blue" to="/settings/clients/overview">
        Clients
      </router-link>
      <router-link class="button-outline-blue" to="/settings/projects/overview">
        Projects
      </router-link>
      <router-link class="button-outline-blue" to="/settings/clients/am">
        Clients / AM
      </router-link>
      <router-link class="button-outline-blue" to="/settings/clients/am-supervisor">
        Clients / AM Supervisor
      </router-link>
      <router-link class="button-outline-blue" to="/settings/clients/pm">
        Clients / PM
      </router-link>
      <router-link class="button-outline-blue" to="/settings/clients/ae">
        Clients / AE
      </router-link>
    </div>
  </hero-navigation>
</template>
<script>
import HeroNavigation from '../components/HeroNavigation.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'settings-navigation',
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['me', 'isAdmin', 'isManager', 'isManagerAndBigger'])
  },
  components: {
    HeroNavigation
  }
}
</script>
