<template>
  <div class="select-renderer projects">
    <v-select
      v-model="selected"
      :options="options"
      :multiple="true"
      v-on:input="onSelectChange"
      :no-async="true"
      appendToBody
      :getOptionLabel="(option) => {
          return option.abbr_l + '-' + option.type
      }"
    >
      <template
        #selected-option-container="{ option, deselect, multiple, disabled }"
      >
        <div :class="['vs__selected', option.status]">
          <span>{{ option.abbr_l }}-{{ option.type }}</span>
          <b-icon v-if="multiple" :disabled="disabled" class="delete" variant="primary" icon="x" @click="deselect(option)"></b-icon>
        </div>
      </template>
      <template #option="option">
        <span>{{ option.abbr_l }}-{{ option.type }}</span>
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data() {
    return {
      selected: [],
      options: []
    }
  },
  mounted() {
    this.options = this.params.values
    this.selected = this.params.data.projects
  },
  methods: {
    onSelectChange(selectedValue) {
      this.params.node.setDataValue(this.params.colDef.field, selectedValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.select-renderer {
  &::v-deep {
    .vs__selected-options {
      width: 300px;
      overflow-x: auto;
      white-space: nowrap;
      flex-wrap: nowrap;
    }
    .vs__selected-options::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
