var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-renderer"},[_c('v-select',{class:[_vm.dynamicClass],attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"clearable":true,"no-async":true,"appendToBody":"","getOptionLabel":function (option) {
        return option.name
      }},on:{"input":_vm.onSelectChange},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
      var name = ref.name;
      var status = ref.status;
return [(status !== 'active')?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(name))]):_c('span',[_vm._v(_vm._s(name))])]}},{key:"option",fn:function(ref){
      var name = ref.name;
      var status = ref.status;
return [(status !== 'active')?_c('span',{staticStyle:{"text-decoration":"line-through"}},[_vm._v(_vm._s(name))]):_c('span',[_vm._v(_vm._s(name))])]}}]),model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }