var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-renderer projects"},[_c('v-select',{attrs:{"options":_vm.options,"multiple":true,"no-async":true,"appendToBody":"","getOptionLabel":function (option) {
        return option.abbr_l + '-' + option.type
    }},on:{"input":_vm.onSelectChange},scopedSlots:_vm._u([{key:"selected-option-container",fn:function(ref){
    var option = ref.option;
    var deselect = ref.deselect;
    var multiple = ref.multiple;
    var disabled = ref.disabled;
return [_c('div',{class:['vs__selected', option.status]},[_c('span',[_vm._v(_vm._s(option.abbr_l)+"-"+_vm._s(option.type))]),(multiple)?_c('b-icon',{staticClass:"delete",attrs:{"disabled":disabled,"variant":"primary","icon":"x"},on:{"click":function($event){return deselect(option)}}}):_vm._e()],1)]}},{key:"option",fn:function(option){return [_c('span',[_vm._v(_vm._s(option.abbr_l)+"-"+_vm._s(option.type))])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }